<template>
	<div class="main">
		<div class="fixed-div"> 
			宽{{windowWidth}}高 {{windowHeight}} 
			分辨率{{screenWidth}}*{{screenHeight}} 
			控制端{{ReMScreenHeight}} {{ReMScreenWidth}} 
			当前坐标位置 {{divX}} {{divY}}相对位置 {{x}} {{y}}  
			{{roll}}
		</div>
		<p v-if='ComputerIP !=""'>正在控制 {{ controleIP }}</p>
		<div v-if="screenByte!=''" >
			<div>
				<el-button type="primary" style="margin-left: 16px" @click="FullView" >全屏显示</el-button>
				<el-button type="primary" style="margin-left: 16px" @click="reconnect" >重新连接</el-button>
			</div>
			<div >
				<img id="scrDIV" :src="screenByte"  :width="ReMScreenWidth" :class="{ zoomIn: Full, zoomOut: !Full }" class="zoom-box"    @mousedown="mouseHandle" @mousemove="mouseMove" @mouseup="endDrag"      @dragstart="dragstart" @drag="drag"  @dragend="dragEnd" draggable="false"> 
			</div>
		</div>
		<div v-else>
			<canvas id="myCanvas" class="cvs"></canvas>
		</div>
	</div>
</template>
<script>
import { onMounted, onUnmounted } from "vue";

import { ElMessage } from 'element-plus'
export default {
	name: 'computerView',
	props: ['controleIP'],
	data() {
		return {
			originH:0,
			originW:0,
			roll:0,
			windowWidth:0,
			windowHeight:0,
			screenWidth:0,
			screenHeight:0,
			divX:308,
			divY:197,
			divH:308,
			divW:197,
			ReMScreenHeight:0,
			ReMScreenWidth:0,
			x:0,
			y:0,
			startX:0,
			startY:0,
			endX:0,
            screenWS:null,
			endY:0,
			tmpIP:"",
			ComputerIP:"",
			screenByte:"",
			Full:false,
		}
	},
	mounted:function () {
		this.GetWindowSize();
		this.ComputerIP = this.controleIP;
		this.GetDivPos();
		this.monitorFocus();
		this.drawing();
		setInterval(this.screenCheck, 1000);
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
	},
	beforeUnmount() {
		window.removeEventListener('keydown', this.ScrollAction);
	},
	setup(){
	},
	methods:{
		ScrollAction(event){
			console.log(event);
		},
		drawing(){
			var canvas = document.getElementById("myCanvas");
			var ctx = canvas.getContext("2d");
			ctx.font = "30px Arial"
			ctx.fillStyle = "#1D002D";
			ctx.fillText("暂无设备连接",canvas.width *0.2,canvas.height * 0.55);
		},
		dragstart(event){
			console.log("开始拖动",event.offsetX,event.offsetY);
		},
		drag(event){
			console.log("正在拖动",event.offsetX,event.offsetY);
		},
		dragEnd(event){
			console.log("拖动结束",event.offsetX,event.offsetY);
		},
		GetWindowSize(){
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
			// 获取屏幕的宽度
			this.screenWidth = screen.width;
			// 获取屏幕的高度
			this.screenHeight = screen.height;
		},
		FullView(){
			this.Full = true;
			document.documentElement.requestFullscreen();
			return
		},
		GetDivPos() {
			var divD = document.getElementById("scrDIV");
			if (!divD){
				return
			}
			var rect = divD.getBoundingClientRect();
			this.divH = rect.x
			this.divW = rect.y
		},
		screenCheck(){
			this.ComputerIP = this.controleIP;
			if (this.ComputerIP == ""){
				return
			}else if (this.screenWS == null) {
				this.ScreenConn();
				this.SocketConn();
				this.GetScreenMsg();
			}else if (this.tmpIP != this.ComputerIP  ){
				this.GetDivPos();
				this.ScrSocketClose();
				this.SocketClose();
				this.SocketConn();
				this.ScreenConn();
				this.GetScreenMsg();
			}
		},
		GetScreenMsg(){
			setInterval(this.SendScreenReq, 100);
		},
		SendScreenReq(){
			this.ScrSocketMessage("screen");
		},
		reconnect(){
			if (this.ComputerIP == ""){
				return
			}
			this.ScreenConn();
			this.GetScreenMsg();
		},
		ScreenConn(){
			var that = this;
			that.screenWS = new WebSocket("ws://"+this.ComputerIP+":9992/v1/socket/screen");
			this.tmpIP = this.ComputerIP;
			that.screenWS.onopen = function(e) {
				if (e == null){
					that.screenWS.send("消息测试");
				}
            }
            that.screenWS.onclose = function(e) {
				if (e != null){
					console.log(e);
					ElMessage("设备已主动断开链接")
					that.screenByte = "";
                }
            }
            that.screenWS.onerror = function(e) {
                console.log(e)
            }
            that.screenWS.onmessage = function(m) {
				var scMsg = JSON.parse(m.data);
				that.screenByte ="data:image/jpeg;base64,"+scMsg.Img;
				that.ReMScreenHeight = scMsg.Height;
				that.ReMScreenWidth = scMsg.Width;
				that.originH = scMsg.Height;
				that.originW = scMsg.Width;
				return
            }
        },
		ScrSocketClose(){
			console.log("设备"+this.ComputerIP+"已离线")
            this.screenWS.close();
        },
        ScrSocketMessage(msg){
			if (this.screenWS == null){
				return
			}
			if (this.screenWS.readyState == 1){
				this.screenWS.send(msg);
			}
			
        },
		SocketConn() {
			var that = this;
			that.output = document.getElementById("output");
			that.ws = new WebSocket("ws://"+this.ComputerIP+":9992/v1/socket/action");
			that.ws.onopen = function(e) {
				if (e == null){
					that.ws.send("消息测试");
				}
			}
			that.ws.onclose = function(e) {
				if (e == null){
					that.log("Disconnected: " + e.reason);
				}
				console.log("设备"+this.ComputerIP+"已离线")
			}
			that.ws.onerror = function(e) {
				that.log("socket 连接失败",e);
			}
			that.ws.onmessage = function(e) {
				if (e.data != ""){
					ElMessage(e.data)
				}
				that.log("Message received: " + e.data);
			}
		},
		SocketClose(){
			this.ws.close();
		},
		sendMessage(msg){
			if (this.ws == null){
				return
			}
			if (this.ws.readyState == 1){
				this.ws.send(msg);
			}
		},
		handleKeyUp(event){
			this.sendMessage("keyDown,"+event.key);
		},
		handleKeyDown(event) {
			if (event.key =="Escape" && this.Full){
				this.Full = false;
				let full = document.fullscreenElement
				if (full){
					document.exitFullscreen();
				}
				
				return
			}
			this.sendMessage("key,"+event.key);
		},
		monitorFocus() {
			//定义一个鼠标滑动时触发方式
			function MovementTrigger(e) {
				//事件触发时给x y 赋值
				this.x.value = e.pageX - this.divY;
				this.y.value = e.pageY - this.divY;
			}
			//onMounted生命周期  执行时页面元素加载完毕  没加载完毕  window事件定义会获取不到
			onMounted(() =>{
				//添加  onmousemove事件   当鼠标滑动时触发MovementTrigger
				window.addEventListener("mousemove",MovementTrigger)
			})
			//onUnmounted 组件销毁时触发事件
			onUnmounted(() =>{
				//注销掉之前定义的on事件  避免报错
				window.removeEventListener("mousemove",MovementTrigger)
			})
		},
		endDrag(event){
			const mouseX = event.clientX - this.divY;
			const mouseY = event.clientY - this.divY;
			this.endX = mouseX;
			this.endY = mouseY;
			console.log("拖动",this.startX,this.startY,"到",mouseX,mouseY);
			this.sendMessage("drag,"+this.startX,+","+this.startY+","+mouseX+","+mouseY)
		},
		mouseMove(event){
			const mouseX = event.offsetX;
			const mouseY = event.offsetY;
			this.x = mouseX;
			this.y = mouseY;
			this.divX = event.clientX;
			this.divY = event.clientY;
			// console.log(event.pageX,event.screenX,event.offsetX,event.clientX)
			this.sendMessage("move,"+mouseX+","+mouseY)
		},
		mouseHandle(event) {
			const mouseX = event.offsetX;
			const mouseY = event.offsetY;

			if(event.button==0){
				console.log('鼠标左键'); 
				this.startX =  mouseX;
				this.startY = mouseY;
				this.sendMessage("left,"+mouseX+","+mouseY);
			}else if (event.button==1){
				console.log('鼠标滚轮',event);

			}else{
				console.log('鼠标右键');
				this.sendMessage("right,"+mouseX+","+mouseY);
			}
		},
		log(s) {
			var p = document.createElement("p");
			p.style.wordWrap = "break-word";
			p.textContent = s;
			// this.output.appendChild(p);
			// console.log(s);
		}
    },
    watch: {
    }, 
}
</script>
<style scoped>
.zoom-box {
	top:1px;
	left:100px;
	position:  absolute;
	background-color: #42b983;
	transition: transform 0.1s;
	cursor: pointer;
	border: 2px solid #DCDCDC;
    padding: 1px;
    border-radius: 5px;
}
.zoomIn {
	position:  absolute;
	border:1px;
	z-index: 10;
	left:0;
	top:0;	
	object-fit: cover;
	transform: scale(1.0);
}
.zoomOut {
	transform: scale(0.6);
}
.fixed-div {
	position: fixed;
	top: 10px;
	right: 40px;
	float:right;
}
.cvs{
	margin-top:20%;
	float:center;
	width:600px; 
	height:200px; 
}
</style>
