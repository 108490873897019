<template>
    <div class="bodyDiv">
        <el-container>
        <el-header class="head_cls">
           客户端日志
            </el-header>
            <el-container>
                <el-aside width="240px" class="aside_cls">
                    <div>
                        <div>
                            <span>
                                <h3>日志列表</h3>
                            </span>
                            <el-tree
                                highlight-current
                                style="max-width: 600px"
                                :data="threeData"
                                @node-click="handleNodeClick"
                            />
                        </div>
                    </div>
                </el-aside>
                <el-main class="main_cls">
                    <div>
                        <el-skeleton :loading="loading" />
                    </div>
                    <div>
                        <el-button color="#626aef"  plain @click="getLogDirApi">更新日志列表</el-button>
                        <el-button type="primary" style="margin-left: 16px" @click="viewLog" :disabled="logName==''">
                            查看日志
                        </el-button>
                        <el-button type="primary" style="margin-left: 16px" @click="autoLoad=true" v-if="autoLoad==false" :disabled="logName==''">
                            自动刷新日志
                        </el-button>
                        <el-button type="primary" style="margin-left: 16px" @click="autoLoad=false" v-else :disabled="logName==''">
                            停止自动刷新日志
                        </el-button>
                    </div>
                    <div>
                        <ComputerView :controleIP="parentMessage" @child-event="handleChildEvent">
                        </ComputerView>
                        <el-drawer
                            v-model="drawer"
                            :title="`[${dir2}] ${logFileName} ${dir3} 日志`"
                            :before-close="handleClose"
                            >
                            <div><el-divider border-style="dashed" >更新于 {{logTimeStamp}}</el-divider></div>
                            <el-watermark :font="font">
                                <div style="height: 680px;">
                                    <iframe :src="logName" scrolling="auto" frameborder="0" class="frame" id="myIframe" ref="myIframe"></iframe>
                                </div>
                            </el-watermark>
                        </el-drawer>
                    </div>
                    <div id="output">
                        
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>    
</template>

<script>
import {getLogDir} from '../api/api'; 
// import {FilePush} from '../api/api'; 
import { ElMessageBox,ElMessage } from 'element-plus'
import ComputerView from './computerView.vue';
import { ref } from 'vue';
export default {
    name: 'HomeView',
    data() {
        return {
            Log_List:[],
            threeData:[],
            Projects:[],
            logFile:[],
            loading:false,
            drawer:false,
            output:null,
            ws:null,
            to:"172.19.52.67",
            dir1:"",
            dir2:"",
            dir3:"",
            logName:"",
            logFileName:"",
            logTimeStamp:"",
            isRead : false,
            autoLoad:false,
            parentMessage:ref(''),
            refreshInterval:5000
        }
    },
    components: { ComputerView },
    mounted:function () {
        this.getLogDirApi();
        this.SocketConn();
        this.startRefreshing();

    },
    methods: {
        handleChildEvent(payload){
            console.log('Event received from child:', payload);
        },
        startRefreshing() {
            this.refreshInterval = setInterval(() => {
                if (this.autoLoad){
                    this.$refs.myIframe.contentWindow.location.reload(true);
                }
            }, this.refreshInterval);
        },
        viewLog(){
            var that = this;
            that.isRead = true
            that.drawer = true
        },
        handleClose(done){
            ElMessageBox.confirm('不再查看日志？').then(
                () => {
                done()
            }).catch(() => {
                // catch error
            })
        },
        handleNodeClick(data, node)  {
            if (node.level == 2) {
                var tmp = data.label.split(":")
                this.to = tmp[1]
                this.dir2 = tmp[0]
                this.parentMessage = tmp[1]
            }else if (node.level == 3){
                node.level == 3
                this.dir3 = data.label
            }else if (node.level == 4){
                if (this.dir2 == "字加"){
                    this.logName = "http://"+this.to+":9992/zijia/" +this.dir3+"/"+data.label;
                }else if (this.dir2 == "方正字库"){
                    this.logName = "http://"+this.to+":9992/ziku/" +this.dir3+"/"+data.label;
                }
                this.logFileName = data.label
                this.isRead = true
                this.drawer = true
                console.log(this.logName);
            }else if (node.level == 1){

                this.logTimeStamp = data.TimeStamp
            }
        },
        stopTail(){
            this.isRead = false;
        },
        tailLog(){
            let msg = {"from":"web","to":this.to,"msgType": 1,"msg":this.logName}
            if (this.isRead) {
                this.isRead = false;
                msg = {"from":"web","to":this.to,"msgType": 1,"msg":"stop"}
            }else{
                this.isRead = true;
            }
            let jsonStr =JSON.stringify(msg);
            this.sendMessage(jsonStr);
        },
        getLogDirApi (){
            getLogDir().then(
                res => {
                    let {code,msg,data} = res;
                    if ( code == 200) {
                        this.Projects = data;
                        this.threeData = [];
                        for (var i = 0; i < data.length; i++) {
                            var d1 = {
                                "label":"设备 ["+data[i].ComputerName+"]",
                                "children":data[i].programs,
                                "TimeStamp":this.ToTime(data[i].timestamp)
                            }
                            this.threeData.push(d1)
                        }
                    }else{
                        ElMessage(msg);
                    }
                }
            )
        },
        ToTime(timestamp){
            var d = new Date(parseInt(timestamp));    //根据时间戳生成的时间对象
            var date = (d.getFullYear()) + "-" +(d.getMonth() + 1) + "-" +(d.getDate()) + " " + (d.getHours()) + ":" + (d.getMinutes()) + ":" + (d.getSeconds());
            return date
        },
        SocketConn() {
            var that = this;
            that.output = document.getElementById("output");
            that.ws = new WebSocket("ws://172.19.52.67:9992/v1/socket/tail");

            that.ws.onopen = function(e) {
                if (e == null){
                    that.ws.send("消息测试");
                }
                
            }
            that.ws.onclose = function(e) {
                if (e == null){
                    that.log("Disconnected: " + e.reason);
                }
            }
        
            that.ws.onerror = function(e) {
                console.log(e)
                that.log("socket 连接失败");
            }
            that.ws.onmessage = function(e) {
                console.log("接收消息");
                that.log("Message received: " + e.data);
            
            }
        },
        SocketClose(){
            this.ws.close();
        },
        sendMessage(msg){
            this.ws.send(msg);
        },
        log(s) {
            var p = document.createElement("p");
            p.style.wordWrap = "break-word";
            p.textContent = s;
            this.output.appendChild(p);
        }
    },
    props: {
        msg: String
    },
    watch: {

    }
}
</script>
<style scoped>
.head_cls{
    background-color:#CDC9C9;
    min-height:20px;
}
.aside_cls{
    background-color:#FFFAFA;
    min-height:600px;
    border: 2px solid #DCDCDC;
    padding: 5px;
    border-radius: 5px;
    display:show;
}
.main_cls{
    background-color:#DCDCDC;
    min-height:845px;
    border: 2px solid #FFFAFA;
    border-radius: 5px;
}


.frame{
    width: 100%;
    height: 78vh;
    background-color:#A2B5CD;
}
.iframe-container {
  width: 100%;
  height: 100vh;
  .frame {
    width: 100%;
    height: 100%;
  }
}
.bodyDiv{
    position: relative;
    z-index: 1;
}
</style>
