import axios from 'axios'
// import md5 from 'js-md5';
export const post = (url, params) => {
    const res = axios({
        method: 'post',
        url: url,
        data: params,
        headers: {
            'channel': "web",
            "version": 1.001
        }
    });
    return res
};


export const put = (url,params) => {
  const res = axios({
    method: 'put',
    url: url,
    data: params,
  });
  return res
};

export const get = (url, params) => {
    if (params != undefined){
        var keys = Object.keys(params);
        var tmp = "?"
        
        for (var i = 0; i < keys.length; i++) {
            if (i == keys.length -1){
                tmp+=keys[i]+"="+params[keys[i]]
           }else{
                tmp+=keys[i]+"="+params[keys[i]]+"&"
           }
            
        }
        url += tmp
        url +="&version=1.001&channel=web"
    }
    return axios({
        method: 'get',
        url: url,
        data: params,
        headers: {
            'channel': "web"
        }
    });
};

export const check = (res) => {
  console.log(res);
} 

export const FilePush = params => { return post('/v1/api/fileup',params).then(res => res.data).catch( 
        function (error) {
            let { msg } = error;
            console.log("接口请求失败"+error);
            return {"code":-1,"msg":msg,"data":null}
        }
    ) 
}
export const getLogDir = () => { return get('/v1/api/logDir').then(res => res.data).catch( 
        function (error) {
            let {msg } = error;
            console.log("接口请求失败"+error);
            return {"code":-1,"msg":msg,"data":null}
        }
    ) 
}